//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoginServices from "../Services/LoginServices";
import configStorage from '../Services/Global/getConfig';
import LoginHelper from "../Services/LoginHelper";

const loginService = new LoginServices();
export default {
  name: "login",
  data() {
    return {
      pin: "",
      usuario: "",
      password: "",
      tituloLogin: "",
    }
  },
  async mounted() {
    this.loader(false);
    await configStorage.getApi();
    let token = unescape(this.$route.query.token.toString());
    let tokenDecode = JSON.parse(atob(token));

    this.axiosSourceToken = this.axiosCancelToken.source();
    this.pin = tokenDecode.pin;
    this.tituloLogin = tokenDecode.tituloLogin;
    localStorage.setItem("cajaId", tokenDecode.cajaId);
    localStorage.setItem("tituloLogin", tokenDecode.tituloLogin);
    localStorage.setItem("tokenLogin", token);

    this.$nextTick(() => {
      if (!tokenDecode.pin) this.$refs.pin.focus();
      else this.$refs.usu.focus();
    });
  },
  methods: {
    async validarLogin() {
      this.loader(true);
      let data = {
        usuario: this.usuario,
        password: this.password,
        pin: this.pin.toUpperCase(),
        token: this.token
      };
      this.limpiarLocalStorage();
      await loginService.realizarLogin(data)
          .catch(error => {
            this.alertShow(error, "error");
            this.loader(false);
          });
      loginService.cargarDatosIniciales()
          .then(() => {
            let caja = JSON.parse(localStorage.getItem("cajaObj")),
                usuarioId = localStorage.getItem("usuarioId");

            LoginHelper.validarMenu(caja);

            if (caja.tipo_operacion === 101) {
              return this.$router.push("/ventaMostrador");
            } else if (!_.isEmpty(caja.operacionCajaId) && caja.operacionCaja.operadorId !== usuarioId) {
              localStorage.setItem("bloqueoVentas", "true");
              localStorage.setItem("bloqueoCaja", "true");
              return this.$router.push({name: "home", query: {tipo: "usuarioError"}});
            } else if (_.isEmpty(caja.operacionCajaId) && Number(caja.tipo_operacion) !== 101) {
              localStorage.setItem("bloqueoVentas", "true");
              localStorage.setItem("bloqueoCaja", "true");
              return this.$router.push({name: "home", query: {tipo: "requiereOperacionCaja"}});
            }

            if (Number(caja.tipo_operacion) === 100)
              return this.$router.push("/cajaMostrador");
            return this.$router.push("/ventaMostrador");
          })
          .catch(error => this.alertShow(error, "error"))
          .then(() => this.loader(false));
    },
    limpiarLocalStorage() {
      let array = ["tokenLogin", "envApikey", "cajaId", "apiUrl", "tituloLogin"];
      for (let localStorageKey in localStorage) {
        if (!array.includes(localStorageKey))
          localStorage.removeItem(localStorageKey);
      }
    }
  }
}
